<template>
  <b-card
    v-if="poll.id"
    no-body
    class="business-card"
  >
    <b-card-header class="pb-1">
      <b-card-title>Tek Soruluk Anket</b-card-title>
    </b-card-header>

    <b-card-body v-if="poll">
      <b-card-text>Aşağıdaki tek soruluk anketi cevaplayarak gelişmemize yardımcı olun.</b-card-text>
      <h6 class="text-danger">
        {{ poll.question }}
      </h6>
      <div
        v-if="poll.voted === 0"
        class="business-items"
      >
        <b-form-group>
          <b-form-radio-group
            v-model="selected"
            :options="poll.options"
            text-field="title"
            value-field="id"
            class="demo-inline-spacing"
            name="radio-inline"
          />
        </b-form-group>
        <div
          v-if="selected"
          class="mb-1"
        >
          <b-form-textarea
            v-model="comment"
            size="sm"
            placeholder="Görüşlerinizi yazabilirsiniz"
          />
        </div>
        <b-button
          :disabled="!selected"
          variant="primary"
          block
          @click="submitVote"
        >
          Gönder
        </b-button>
      </div>
      <div
        v-else
        class="business-items"
      >
        <div
          v-for="result in pollResult.options"
          :key="result.id"
        >
          <label>{{ result.title }}</label>
          <b-progress
            max="100"
            variant="info"
            class="progress-bar-info"
          >
            <b-progress-bar :value="result.percent">
              <strong>% {{ result.percent }}</strong>
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
    </b-card-body>
    <empty-alert
      v-else
      text="Güncel anket bulunmamaktadır."
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BFormRadioGroup, BFormGroup, BButton, BProgress, BProgressBar, BFormTextarea,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    BProgress,
    BProgressBar,
    BFormTextarea,
    EmptyAlert,
  },
  data() {
    return {
      machineCode: null,
      selected: null,
      comment: null,
      options: [
        { text: 'Memnunum', value: 'first' },
        { text: 'Memnun Değilim', value: 'second' },
      ],
    }
  },
  computed: {
    poll() {
      const data = this.$store.getters['poll/getPoll']
      return data
    },
    pollResult() {
      return this.$store.getters['poll/getPollResult']
    },
  },
  created() {
    this.setMachineCode()
    this.getPoll()
    this.getPollResult()
  },
  methods: {
    getPoll() {
      this.$store.dispatch('poll/poll', { machine_code: this.machineCode })
    },
    getPollResult() {
      if (this.poll.id) {
        this.$store.dispatch('poll/pollResult', this.poll.id)
      }
    },
    submitVote() {
      const data = {
        id_com_portal_poll_options: this.selected,
        id_com_portal_polls: this.poll.id,
        machine_code: this.machineCode,
        comment: this.comment,
      }
      this.$store.dispatch('poll/pollVote', data)
    },
    setMachineCode() {
      if (!localStorage.getItem('machineCode')) {
        const machineCode = Math.floor(Math.random() * 12345678) * Math.floor(Math.random() * 99999999)
        localStorage.setItem('machineCode', machineCode)
        this.machineCode = machineCode
      } else {
        this.machineCode = localStorage.getItem('machineCode')
      }
    },
  },
}
</script>
