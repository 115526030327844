<template>
  <b-card
    title="Ayın Röportajı"
  >
    <div v-if="reportage.id">
      <b-card-sub-title>{{ reportage.title }}</b-card-sub-title>
      <img
        :src="baseurl + '/media/portal/reportage/' + reportage.image"
        class="w-100 mb-2 mt-2"
      >
      <b-card-text>
        <div v-html="reportage.summary" />
      </b-card-text>
      <b-button
        v-b-modal.modal-reportage
        block
        variant="danger"
      >
        Tamamını Oku
      </b-button>
    </div>
    <empty-alert
      v-else
      text="Üzgünüz henüz ayın röportajı yüklenmemiş."
    />
    <b-modal
      id="modal-reportage"
      centered
      size="lg"
      :title="reportage.title"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        <div class="text-center mb-1">
          <b-img
            v-if="reportage.image"
            :src="baseurl + '/media/portal/reportage/' + reportage.image"
            blank-color="#ccc"
            fluid
            rounded="rounded"
          />
        </div>
        <div v-html="reportage.content" />
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BImg,
  BCard,
  BCardSubTitle,
  BCardText,
  BButton,
  BModal,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'Reportage',
  components: {
    BImg,
    BCard,
    BCardSubTitle,
    BCardText,
    BButton,
    BModal,
    EmptyAlert,
  },
  data() {
    return {
      baseurl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    reportage() {
      return this.$store.getters['reportage/getReportage']
    },
  },
  created() {
    this.getReportage()
  },
  methods: {
    getReportage() {
      this.$store.dispatch('reportage/reportage')
    },
  },
}
</script>

<style scoped>

</style>
