<template>
  <b-card-code title="Etkinlik Takvimi">
    <app-timeline v-if="events.length > 0">
      <app-timeline-item
        v-for="(event,key) in events"
        :key="event.id"
        :title="event.title"
        :subtitle="event.summary"
        :icon="event.icon"
        :time="moment(event.edate).format('DD.MM.YYYY')"
        :variant="colors[key]"
      />
    </app-timeline>
    <empty-alert
      v-else
      text="Henüz planlanmış etkinlik bulunmamaktadır."
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  components: {
    BCardCode,
    AppTimeline,
    AppTimelineItem,
    EmptyAlert,
  },
  data() {
    return {
      colors: ['success', 'danger', 'info', 'warning', 'primary'],
    }
  },
  computed: {
    events() {
      return this.$store.getters['events/getEvents']
    },
  },
  created() {
    this.getEvents()
  },
  methods: {
    getEvents() {
      this.$store.dispatch('events/events', {
        where: { 'com_portal_events.edate >=': this.moment().format('YYYY-MM-DD') },
        order_by: ['com_portal_events.edate', 'ASC'],
        limit: 5,
      })
    },
  },
}
</script>
