<template>
  <b-card
    title="Günün Motivasyon Sözü"
  >
    <b-card-text v-if="today">
      <blockquote class="blockquote pl-1 border-left-danger border-left-3">
        <p class="mb-0">
          {{ today.content }}
        </p>
        <footer class="blockquote-footer">
          {{ today.name }}
        </footer>
      </blockquote>
    </b-card-text>
    <empty-alert
      v-else
      text="Henüz günün motivasyon sözü eklenmemiş"
    />
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'QuoteOfDay',
  components: {
    BCard,
    BCardText,
    EmptyAlert,
  },
  computed: {
    today() {
      return this.$store.getters['quoteOfDays/getToday']
    },
  },
  created() {
    this.getToday()
  },
  methods: {
    getToday() {
      this.$store.dispatch('quoteOfDays/today')
    },
  },
}
</script>

<style scoped>

</style>
