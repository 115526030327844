<template>
  <div v-if="notices.length > 0">
    <h3>Duyurular</h3>
    <hr>
    <b-card class="custom-bg">
      <b-carousel
        id="notification-carousel"
        :interval="5000"
      >
        <b-carousel-slide
          v-for="notice in notices"
          :key="notice.id"
        >
          <template #default>
            <a
              v-b-modal.modal-center
              href="#"
              @click="setModal(notice)"
            >
              <h3>{{ notice.title }}</h3>
              <p>{{ notice.content }}</p>
            </a>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-card>
    <b-modal
      id="modal-center"
      centered
      :title="modalData.title"
      ok-only
      size="lg"
      ok-title="Kapat"
    >
      <b-card-text>
        <div>
          {{ modalData.content }}
        </div>
        <hr v-if="modalData.image">
        <b-img
          v-if="modalData.image"
          :src="modalData.image"
          fluid
          class="mt-1"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BModal,
  BCardText,
  BImg,
  VBModal,
} from 'bootstrap-vue'

export default {
  name: 'Notifications',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardText,
    BModal,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      modalData: {
        title: null,
        content: null,
        image: null,
      },
    }
  },
  computed: {
    notices() {
      return this.$store.getters['notices/getNotices']
    },
  },
  created() {
    this.getNotices()
  },
  methods: {
    getNotices() {
      this.$store.dispatch('notices/notices', {
        where: {
          'com_portal_notices.status': 1,
          'com_portal_notices.edate >=': this.moment().format('YYYY-MM-DD'),
        },
      })
    },
    setModal(data) {
      if (data.image) {
        data.image = `${this.$store.state.app.baseURL}/media/portal/notices/${data.image}`
      }
      this.modalData.title = data.title
      this.modalData.content = data.content
      this.modalData.image = data.image
    },
  },
}
</script>

<style>
.custom-bg {
  background-color: #f5f5f5;
}
#notification-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
#notification-carousel h3 {
  font-size: 16px;
}
#notification-carousel p {
  font-size: 12px;
  margin: 0;
}
</style>
