<template>
  <b-card :style="'height:' + getHeight() + 'px'">
    <b-card-title>Yemek Menüsü <small class="text-muted">Bugün ve Yarın</small></b-card-title>
    <b-carousel
      v-if="meals"
      id="meal-carousel"
      :interval="5000"
    >
      <b-carousel-slide>
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              {{ moment().format('LL') }}
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-for="meal in meals.today"
            :key="meal.id"
            class="d-flex"
          >
            <span class="mr-1">
              <feather-icon
                icon="StarIcon"
                size="16"
              />
            </span>
            <span>{{ meal.title }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-carousel-slide>
      <b-carousel-slide>
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              {{ moment().add('days', +1).format('LL') }}
            </div>
          </b-list-group-item>
          <b-list-group-item
            v-for="yMeal in meals.tomorrow"
            :key="yMeal.id"
            class="d-flex"
          >
            <span class="mr-1">
              <feather-icon
                icon="StarIcon"
                size="16"
              />
            </span>
            <span>{{ yMeal.title }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-carousel-slide>
    </b-carousel>
    <empty-alert
      v-else
      text="Güncel yemek menüsü henüz yüklenmemiş."
    />
  </b-card>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCard, BCardTitle, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BCardTitle,
    BCarousel,
    BCarouselSlide,
    EmptyAlert,
  },
  computed: {
    meals() {
      return this.$store.getters['meals/getMeals']
    },
  },
  created() {
    this.getMeals()
  },
  methods: {
    getMeals() {
      this.$store.dispatch('meals/meals', {
        where: {
          'com_portal_meals.mdate': this.moment().format('YYYY-MM-DD'),
        },
      })
    },
    getHeight() {
      let height = 'auto'
      if (this.meals) {
        if (this.meals.today.length > this.meals.tomorrow.length) {
          height = (this.meals.today.length * 42) + 120
        } else {
          height = (this.meals.tomorrow.length * 42) + 120
        }
      }
      return height
    },
  },
}
</script>
<style>
#meal-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
#meal-carousel span {
  font-size: 12px;
  color: #000;
}

</style>
