<template>
  <b-card
    title="Bugün Doğanlar"
  >
    <b-carousel
      v-if="birthdays.length > 0"
      id="awards-carousel"
      :interval="5000"
    >
      <b-carousel-slide
        v-for="birthday in birthdays"
        :key="birthday.id"
      >
        <b-card>
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                v-if="birthday.image"
                :src="birthday.image"
                size="64"
              />
              <b-img
                v-else
                src="/media/portal/cake.jpg"
                blank-color="#ccc"
                width="64"
                rounded="circle"
              />
            </template>
            <h4 class="media-heading">
              {{ birthday.name }}
              {{ birthday.surname }}
              <span class="d-block font-small-2 text-warning">{{ birthday.title }}</span>
            </h4>
            <b-card-text>
              <p>{{ birthday.content }}</p>
            </b-card-text>
          </b-media>
        </b-card>
      </b-carousel-slide>
    </b-carousel>
    <empty-alert
      v-else
      text="Bugün doğum günü olan hiç personelimiz yok. :)"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCarousel,
  BCarouselSlide,
  BImg,
  BCardText,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Portal/components/EmptyAlert.vue'

export default {
  name: 'EmployeeOfMonth',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BImg,
    BCardText,
    BMedia,
    BAvatar,
    EmptyAlert,
  },
  computed: {
    birthdays() {
      return this.$store.getters['birthdays/getBirthdays']
    },
  },
  created() {
    this.getBirthdays()
  },
  methods: {
    getBirthdays() {
      this.$store.dispatch('birthdays/birthdays')
    },
  },
}
</script>

<style>
#awards-carousel .carousel-caption {
  position: static !important;
  text-align: left;
  padding: 0;
}
body #awards-carousel .carousel-caption p {
  color: #6e6b7b;
  margin: 0;
}
#awards-carousel .carousel-control-next {
  right: -40px
}
#awards-carousel .carousel-control-prev {
  left: -40px;
}
#awards-carousel .congratulation-medal {
  top: -15px;
}
.dark-layout #awards-carousel .carousel-caption p {
  color: #b4b7bd;
}
body #awards-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
.dark-layout #awards-carousel .carousel-control-next-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white"%20stroke-width="2"%20stroke-linecap="round"%20stroke-linejoin="round"%20class="feather%20feather-chevron-right"><polyline%20points="9%2018%2015%2012%209%206"></polyline></svg>');
}
body #awards-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
.dark-layout #awards-carousel .carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}
</style>
