<template>
  <div>
    <b-row>
      <!--      <b-col>-->
      <!--        <slider />-->
      <!--      </b-col>-->
      <b-col>
        <notifications />
      </b-col>
    </b-row>
    <!--    <b-row>-->
    <!--      <b-col>-->
    <!--        <tabSales />-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <b-row class="match-height align-self-stretch">
      <b-col
        md="4"
        sm="12"
      >
        <birthday />
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <quoteOfDay />
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <poll />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="4"
        sm="12"
      >
        <meal />
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <events />
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <reportage />
      </b-col>
    </b-row>
    <b-row class="match-height align-self-stretch">
      <b-col
        md="4"
        sm="12"
      >
        <newEmployee />
      </b-col>
      <b-col
        md="8"
        sm="12"
      >
        <awards />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <campaigns />
      </b-col>
      <b-col cols="12">
        <campaign-cars />
      </b-col>
    </b-row>
    <b-row class="match-height align-self-stretch">
      <b-col
        md="6"
        sm="12"
      >
        <gallery />
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <organization />
      </b-col>
    </b-row>
    <b-row class="match-height align-self-stretch">
      <b-col
        md="6"
        sm="12"
      >
        <career />
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <insurance />
      </b-col>
    </b-row>
    <b-row class="match-height align-self-stretch">
      <b-col
        md="6"
        sm="12"
      >
        <bes />
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <contracted-companies />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        md="12"
      >
        <doctor-calendar />
      </b-col>
    </b-row>
    <!--
    <b-row>
      <b-col>
        <instagram />
      </b-col>
    </b-row>
    -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import notifications from '@/views/Portal/components/Notifications.vue'
import newEmployee from '@/views/Portal/components/NewEmployee.vue'
import awards from '@/views/Portal/components/Awards.vue'
import Events from '@/views/Portal/components/Events.vue'
import meal from '@/views/Portal/components/Meal.vue'
import campaigns from '@/views/Portal/components/Campaigns.vue'
import CampaignCars from '@/views/Portal/components/CampaignCars.vue'
import Gallery from '@/views/Portal/components/Gallery.vue'
import career from '@/views/Portal/components/Career.vue'
import insurance from '@/views/Portal/components/Insurance.vue'
import birthday from '@/views/Portal/components/Birthday.vue'
import quoteOfDay from '@/views/Portal/components/QuoteOfDay.vue'
import poll from '@/views/Portal/components/Poll.vue'
import Reportage from '@/views/Portal/components/Reportage.vue'
// import tabSales from '@/views/Portal/components/tabSales.vue'
import Bes from '@/views/Portal/components/Bes.vue'
import ContractedCompanies from '@/views/Portal/components/ContractedCompanies.vue'
import Organization from '@/views/Portal/components/Organization.vue'
import DoctorCalendar from '@/views/Portal/components/DoctorCalendar.vue'
// import slider from '@/views/Portal/components/Slider.vue'

export default {
  name: 'Portal',
  components: {
    BRow,
    BCol,
    notifications,
    newEmployee,
    Events,
    meal,
    campaigns,
    CampaignCars,
    Gallery,
    career,
    insurance,
    awards,
    birthday,
    quoteOfDay,
    poll,
    Reportage,
    // tabSales,
    Bes,
    ContractedCompanies,
    Organization,
    DoctorCalendar,
    // slider,
  },
}
</script>

<style scoped>

</style>
